import * as React from "react"


// markup
const CourseCard = ({ code, name, image, url }) => {
    return (
        <div className="card mb-4">
            <img src={image} className="card-img-top" alt={name} />
            <div className="card-body p-3">
                <h2 className="card-title mb-3 text-uppercase"><span className="fw-light">{code}</span> <span className="text-primary">&bull;</span> {name}</h2>
                <div className="d-flex">
                    <a href={url} className="ms-auto d-inline-block btn btn-primary text-white text-uppercase rounded-pill">Course website</a>
                </div>
            </div>
        </div>
    )
}

export default CourseCard;
