import * as React from "react"
import { graphql } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"
import Footer from "../parts/Footer"
import Header from "../parts/Header"
import CourseCard from "../components/teaching/CourseCard"
import CustomHelmet from "../components/seo/CustomHelmet"


// markup
const Teaching = ({ location, data }) => {
  const canonicalUrl = data.site.siteMetadata.siteUrl + location.pathname
  
  const { allMarkdownRemark } = data;
  const { nodes } = allMarkdownRemark;
  const courses = nodes;
  return (
    <>
      <CustomHelmet title={"Teaching"} canonicalUrl={canonicalUrl} />
      <Header activeKey="/teaching" />
      <main className="py-5" style={{ marginTop: '81px' }}>
        <Container>
          <h1 className="mb-4">Courses</h1>

          <Row>
            {courses.map(course => <Col key={course.id} sm={12} md={6} lg={4}><CourseCard
              code={course.frontmatter.code}
              name={course.frontmatter.name}
              image={course.frontmatter.image}
              url={course.frontmatter.url}
            /></Col>)}
          </Row>


        </Container>
      </main>
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
{
    allMarkdownRemark(
      filter: {frontmatter: {taxonomy: {eq: "teaching"}}}
      sort: {order: DESC, fields: frontmatter___slug}
    ) {
      nodes {
        frontmatter {
          slug
          code
          name
          image
          url
        }
        id
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

export default Teaching;
